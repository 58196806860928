import debounce from "lodash";

function initOwnershipMemberSelect2 (container) {
  container = container || $(document);
  container.find('[rel="ownership_member"]').select2({
    placeholder: "Įrašykite nario pavadinimą",
    minimumInputLength: 2,
    maximumSelectionLength: 1,
    language: lt,
    ajax: {
      quietMillis: 500,
      url: "/members.json?skip_create=1",
      dataType: "json",
      data: function(term, page) {
        return {
          q: { name: term.term },
          page_limit: 100,
          page: page
        };
      },
      processResults: function(data) {
        return { results: data.members }
      },
      results: function(data, page) {
        return {
          results: data.members,
          more: (page * 100) < data.total
        };
      }
    },
  });

  $(container.find('[rel="ownership_member"]')).each(function(){
    var member_ids = $(this).data('selected');
    if(!!member_ids){
      fetchMemberSelections($(this), "/members.json?skip_create=1", [member_ids])
    }
  });

  function fetchMemberSelections(field, url, params){
    if(params.length > 0){
      var select_field = $(field);
      $.ajax({
        type: 'GET',
        url: url,
        data: {
          q: { members_id_in: params }
        }
      }).then(function (data) {
        data.members.map(function(el, index){
          var option = new Option(el.text, el.id, true, true);
          select_field.append(option).trigger('change');
        });
      });
    }
  }
};

function loadOwnershipsControls () {
  var defaultSelect2Opts = {
    allowClear: true,
    placeholder: 'Pasirinkite',
    width: '100%'
  };

  $('.recalculate').on('click', function(e){
    var ids = [];
    $('.recalculate').map( function(id, el){ 
      if (el.checked){
        ids.push(el.value); 
      }
    });
    if (ids.length > 0){
      $('#recalculate_payouts').removeClass('disabled')
      // $('#recalculate_payouts').attr('data-ownerships', ids);
    }else{
      $('#recalculate_payouts').addClass('disabled')
      // $('#recalculate_payouts').attr('data-ownerships', '');
    }
  })

  $('#recalculate_payouts').on('click', function(e) {
    let distribution_year = prompt('Įveskite metus, kuriems norite perskaičiuoti išmokas');
    let ids = [];
    let url = e.target.href + '?';

    $('.recalculate').map( function(id, el){
      if (el.checked){
        ids.push(el.value);
      }
    });
    $.each(ids, function(id, el) {
      url = url + 'ids[]=' + el + '&';
    });

    if(distribution_year){
      url = url + 'distribution_year=' + distribution_year;
    }
    $(this).attr('href', url);
  });

  initializeSelects();

  setSliderPercentageSum();

  $(document).on('input', '.percentage', setSliderPercentageSum);

  $('#ownership_members').on('cocoon:after-insert', initializeSelects);
  $('#ownership_members').on('cocoon:after-remove', setSliderPercentageSum());

  function initializeSelects (event, element) {
    initOwnershipMemberSelect2(element);
    $(":not(.modal-select) [rel='select2']").each(function () {
      $(this).select2(defaultSelect2Opts);
    });
  }

  $('.duplicate_form_ownership .btn[type=submit]').on('click', function(e){
    e.preventDefault();
    var that = $(this);
    var ownership_id = $(that).closest('form').find('#ownership_ownership_id').val();
    var television_program_id = $(that).closest('form').find('#ownership_television_program_id').val();
    var ownership_valid_from = $(that).closest('form').find('#ownership_valid_from').val();
    var ownership_valid_until = $(that).closest('form').find('#ownership_valid_until').val();
    var params_to_check = {
      id: ownership_id,
      ownership: {
        valid_from: ownership_valid_from,
        valid_until: ownership_valid_until
      }
    }
    // /television_programs/:television_program_id/ownerships/transfer_intersections
    $.get('/television_programs/'+television_program_id+'/ownerships/'+ownership_id+'/duplicate_intersections', params_to_check, function(response) {
      if (Number(response) > 0){
        var prompt_text = 'Galiojimo periodas jau priskirtas kitai teisių grupei.\nAr pageidaujate užregistruoti konfliktą?';
        if(confirm(prompt_text) == true){
          var hiddenInput = $('<input/>', { type: 'hidden', value: true, name: 'create_conflict' });
          $('.duplicate_form_ownership').append(hiddenInput);
          $(that).closest('form').trigger('submit');
        }else{
          $(that).closest('form').trigger('submit');
        }
      }else{
          $(that).closest('form').trigger('submit');
      }
    });
  });

  $('.transfer_form_ownership .btn[type=submit]').on('click', function(e){
    e.preventDefault();
    var that = $(this);
    var television_program_id = $(that).closest('form').find('#ownership_television_program_id').val();
    var new_television_program_id = $(that).closest('form').find('#ownership_new_television_program_id').val();
    var ownership_id = $(that).closest('form').find('#ownership_ownership_id').val();
    var params_to_check = {
      id: ownership_id,
      new_television_program_id: new_television_program_id
    }
    // /television_programs/:television_program_id/ownerships/:ownership_id/transfer_intersections(.:format)
    $.get('/television_programs/'+television_program_id+'/ownerships/'+ownership_id+'/transfer_intersections', params_to_check, function(response) {
      if (Number(response) > 0){
        var prompt_text = 'Galiojimo datos susikerta su kitomis teisių grupėmis.\nAr pageidaujate užregistruoti konfliktą?';
        if(confirm(prompt_text) == true){
          var hiddenInput = $('<input/>', { type: 'hidden', value: true, name: 'create_conflict' });
          $('.transfer_form_ownership').append(hiddenInput);
          $(that).closest('form').trigger('submit');
        }else{
          $(that).closest('form').trigger('submit');
        }
      }else{
          $(that).closest('form').trigger('submit');
      }
    });
  });

  $('.new-ownership .btn[type=submit]').on('click', function(e){
    e.preventDefault();
    var that = $(this);
    var television_program_id = $(that).closest('form').find('#ownership_television_program_id').val();
    var ownership_valid_from = $(that).closest('form').find('#ownership_valid_from').val();
    var ownership_valid_until = $(that).closest('form').find('#ownership_valid_until').val();
    var ownership_valid_rights = Array.from($('#new_ownership input:checkbox:checked').map(function(i, el){
      return $(el).val();
    }));
    var params_to_check = {
      ownership: {
        valid_from: ownership_valid_from,
        valid_until: ownership_valid_until,
        right_ids: ownership_valid_rights
      }
    }
    $.post('/television_programs/'+television_program_id+'/ownerships/check_for_conflict', params_to_check, function(response) {
      if (Number(response) > 0){
        var prompt_text = 'Galiojimo laikotarpis jau priskirtas kitai teisių grupei.\nAr pageidaujate užregistruoti konfliktą?';
        if(confirm(prompt_text) == true){
          var hiddenInput = $('<input/>', { type: 'hidden', value: true, name: 'create_conflict' });
          $('.new-ownership').append(hiddenInput);
        }
        $(that).closest('form').trigger('submit');
      }else{
        $(that).closest('form').trigger('submit');
      }
    });
  });

  $('.edit-ownership .btn[type=submit]').on('click', function(e){
    e.preventDefault();
    var that = $(this);
    var ownership_id = $(that).closest('form').find('#ownership_id').val();
    var television_program_id = $(that).closest('form').find('#ownership_television_program_id').val();
    var ownership_valid_from = $(that).closest('form').find('#ownership_valid_from').val();
    var ownership_valid_until = $(that).closest('form').find('#ownership_valid_until').val();
    var ownership_valid_rights = Array.from($('.edit-ownership input:checkbox:checked').map(function(i, el){
      return $(el).val();
    }));
    var params_to_check = {
      ownership: {
        id: ownership_id,
        valid_from: ownership_valid_from,
        valid_until: ownership_valid_until,
        right_ids: ownership_valid_rights
      }
    }
    $.post('/television_programs/'+television_program_id+'/ownerships/check_for_conflict', params_to_check, function(response) {
      if (Number(response) > 0){
        var prompt_text = 'Galiojimo laikotarpis jau priskirtas kitai teisių grupei.\nAr pageidaujate užregistruoti konfliktą?';
        if(confirm(prompt_text) == true){
          var hiddenInput = $('<input/>', { type: 'hidden', value: true, name: 'create_conflict' });
          $('.edit-ownership').append(hiddenInput);
        }
        $(that).closest('form').trigger('submit');
      }else{
        $(that).closest('form').trigger('submit');
      }
    });
  });
}

function customConfirmation(text, confirm, decline){
  var box = $('#avakaConfirmBox');
  box.find('message').html(text);
  box.find('confirm').unbind().click(function(){
    box.hide();
  })
  box.find('confirm').click(confirm);
  box.find('decline').unbind().click(function(){
    box.hide();
  })
  box.find('decline').click(decline);
}

$(function() {
  loadOwnershipsControls();
});