$(function () {
  function initializeProgramsSelects(event, element){
    let defaultSelect2Opts = {
      allowClear: true,
      placeholder: 'Pasirinkite',
      width: '100%'
    };

    let programs_options = $.extend(defaultSelect2Opts, {
      placeholder: 'Įrašykite pavadinimą',
      minimumInputLength: 2,
      multiple: false,
      width: '100%',
      language: lt,
      dropdownParent: $(element),
      ajax: {
        url: "/television_programs.json",
        dataType: 'JSON',
        quietMillis: 300,
        data: function (term) {
          return {
            q: { name_cont: term.term },
          };
        },
        processResults: function(data) {
          return { results: data.television_programs }
        },
        results: function (data) {
          return { results: data.results };
        }
      },
    });

    $("[rel='sandboxed-programs']").select2(programs_options);
  }

  function initializeMemberSelects(event, element){
    let defaultSelect2Opts = {
      allowClear: true,
      placeholder: 'Pasirinkite',
      width: '100%'
    };

    let member_options = $.extend(defaultSelect2Opts, {
      placeholder: 'Įrašykite pavadinimą',
      minimumInputLength: 2,
      multiple: false,
      width: '100%',
      language: lt,
      // dropdownParent: $('#programs-modal'),
      ajax: {
        quietMillis: 500,
        url: "/members.json?skip_create=1",
        dataType: "json",
        data: function(term, page) {
          return {
            q: { name: term.term },
            page_limit: 100,
            page: page
          };
        },
        processResults: function(data) {
          return { results: data.members }
        },
        results: function(data, page) {
          return {
            results: data.members,
            more: (page * 100) < data.total
          };
        }
      },
    });

    // $("[rel='sandboxed-members']").each(function(i, el){
    //   console.log($(el));
    //   $(el).select2(member_options);
    // });
    $("[rel='sandboxed-members']").select2(member_options);
  }

  function duplicate_intersections(sandboxed_ownership, button){
    let href = '/sandboxed_ownerships/'+sandboxed_ownership+'/confirm?';
    $.getJSON('/sandboxed_ownerships/'+sandboxed_ownership+'/duplicating_ownerships', function(data){
      if(data > 0){
        var prompt_text = 'Galiojimo datos susikerta su kitomis teisių grupėmis.\nAr pageidaujate užregistruoti konfliktą?';
        if(confirm(prompt_text) == true){
          href += 'create_conflict=true&';
        }
      }
      href += 'ownership=true';
      window.location = href;
    });
  }

  $('.o-confirm').on('click', function(e){
    e.preventDefault();
    var sandboxed_ownership = $(this).data('id');
    var prompt_text = 'Ar tikrai norite perkelti teisių turėtojus?';
    if(confirm(prompt_text) == true){ 
      duplicate_intersections(sandboxed_ownership, $(this));
    }
  });
  
  // $('.o-migrate-confirm').on('click', function(e){
  //   e.preventDefault();
  //   var sandboxed_ownership = $(this).data('id');
  //   var prompt_text = 'Ar tikrai norite patvirtinti registraciją ir perkelti informaciją?';
  //   if(confirm(prompt_text) == true){
  //     duplicate_intersections(sandboxed_ownership, $(this), true);
  //   }
  // });

  $('#programs-modal').on('shown.bs.modal', function (e) {
    initializeProgramsSelects(e, this);
  });

  $('#members-modal').on('shown.bs.modal', function (e) {
    initializeMemberSelects(e, this);
  });

});