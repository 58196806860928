function loadTelevisionProgramsControls () {
  var select_field = $("#q_ownership_members_member_id_in, #q_members_id_in, #q_members",).select2({
    allowClear: true,
    placeholder: "Įrašykite nario pavadinimą",
    minimumInputLength: 2,
    multiple: true,
    width: '100%',
    language: lt,
    ajax: {
      quietMillis: 500,
      url: "/members.json?skip_create=1",
      dataType: "json",
      data: function(term, page) {
        return {
          q: { name: term.term },
          page: page
        };
      },
      processResults: function(data) {
        return { results: data.members }
      },
      results: function(data, page) {
        return {
          results: data.members,
          more: (page * 100) < data.total
        };
      }
    },
  });

  if(!!$('#q_ownership_members_member_id_in, #q_members_id_in, #q_members').data('selected') && $('#q_ownership_members_member_id_in, #q_members_id_in, #q_members').data('selected').length > 0){
    fetchMemberSelections(select_field, "/members.json?skip_create=1", $('#q_ownership_members_member_id_in, #q_members_id_in, #q_members').data('selected'))
  }

  initializeStaffMemberSearch(document, true);
};

function initializeStaffMemberSearch (container, include_all_fields) {
  if(include_all_fields){
    var staff_members_searches = $('[rel="staff_members"]');
    for(var i = 0; i < staff_members_searches.length; i++){
      var selected_data = $(staff_members_searches[i]).data('selected');
      if(selected_data.length > 0){
        fetchMemberSelections(staff_members_searches[i], "/members.json?skip_create=1", selected_data)
      }
    }
  }
  $(container).find('[rel="staff_members"]').select2({
    placeholder: "Įrašykite nario pavadinimą",
    minimumInputLength: 2,
    multiple: true,
    width: '100%',
    language: lt,
    ajax: {
      quietMillis: 500,
      url: "/members.json",
      dataType: "json",
      data: function(term, page) {
        return {
          q: { name: term.term },
          page_limit: 100,
          page: page
        };
      },
      processResults: function(data) {
        return { results: data.members }
      },
      results: function(data, page) {
        return {
          results: data.members,
          more: (page * 100) < data.total
        };
      }
    },
  });
}

function fetchMemberSelections(field, url, params){
  if(params.length > 0){
    var select_field = $(field);
    $.ajax({
      type: 'GET',
      url: url,
      data: {
        q: { members_id_in: params }
      }
    }).then(function (data) {
      data.members.map(function(el, index){
        var option = new Option(el.text, el.id, true, true);
        select_field.append(option).trigger('change');
      });
    });
  }
}

$(function() {
  loadTelevisionProgramsControls();
})
