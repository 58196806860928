function televisionPrograms() {
  if ($("#q_television_program_id_in, #q_programs, [rel='select2-conflict']").length > 0) {
    var tv_program_field = $("#q_television_program_id_in, #q_programs")
    var select_field = $(tv_program_field).select2({
      allow_clear: true,
      placeholder: 'Įrašykite pavadinimą',
      minimumInputLength: 2,
      multiple: true,
      width: '100%',
      language: lt,
      ajax: {
        url: "/television_programs.json",
        dataType: 'JSON',
        quietMillis: 500,
        data: function (term) {
          return {
            q: { name_cont: term.term },
          };
        },
        processResults: function(data) {
          return { results: data.television_programs }
        },
        results: function (data) {
          return { results: data.results };
        }
      },
    });
    
    if(!!$('#q_television_program_id_in, #q_programs').data('selected') && $('#q_television_program_id_in, #q_programs').data('selected').length > 0){
      fetchTelevisionProgramSelections(select_field, "/television_programs.json", $('#q_television_program_id_in, #q_programs').data('selected'))
    }
  }
};

function fetchTelevisionProgramSelections(field, url, params){
  if(params.length > 0){
    var select_field = $(field);
    $.ajax({
      type: 'GET',
      url: url,
      data: {
        q: { id_in: params }
      }
    }).then(function (data) {
      data.television_programs.map(function(el, index){
        var option = new Option(el.text, el.id, true, true);
        select_field.append(option).trigger('change');
      });
    });
  }
}

$(function() {
  televisionPrograms();
  $(document).on('shown.bs.modal', function(e){
    var defaultSelect2Opts = {
      allowClear: true,
      placeholder: 'Pasirinkite',
      width: '100%',
    };

    $(e.target).find(".modal-select[rel='select2']").each(function() {
      $(this).select2(defaultSelect2Opts);
    });

    $(e.target).find("#ownership_new_television_program_id[rel='select2']").each(function() {
      $(this).select2({
        placeholder: 'Įrašykite pavadinimą',
        minimumInputLength: 2,
        multiple: true,
        width: '100%',
        language: lt,
        ajax: {
          url: "/television_programs.json",
          dataType: 'JSON',
          quietMillis: 500,
          data: function (term) {
            return {
              q: { 
                id_in_or_name_cont: term.term,
              },
            };
          },
          processResults: function(data) {
            return { results: data.television_programs }
          },
          results: function (data) {
            return { results: data.results };
          }
        },
      });
    });
  });

  $('.submit-modal').on('click', function(){
    $(this).modal('hide');
    $('.modal-backdrop').remove();
  });
});