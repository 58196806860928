/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.$ = window.jquery = jQuery;

import 'cocoon-js';
import 'select2';
import lt from 'select2/src/js/select2/i18n/lt.js'
import 'bootstrap';
import flatpickr from "flatpickr"
import { Lithuanian } from "flatpickr/dist/l10n/lt.js"
import "flatpickr/dist/flatpickr.min.css"
import '@fortawesome/fontawesome-free/js/all';

import '../styles/application.scss';

import './custom.js';
import './distributions.js';
import './distribution_write_offs.js';
import './invoices.js';
import './ownerships.js';
import './potential_ownerships';
import './sandboxed_ownerships.js';
import './seasons.js';
import './television_programs.js';

Rails.start();

$(function() {
  var defaultSelect2Opts = {
    allowClear: true,
    placeholder: 'Pasirinkite',
    width: '100%'
  };
  // initializeSelects();
  flatpickr(".datepicker", {
    locale: Lithuanian,
    allowInput: true,
  });

  flatpickr(".datepicker-time", {
    locale: Lithuanian,
    allowInput: true,
    dateFormat: 'H:i:S',
    enableTime: true,
    enableSeconds: true,
    noCalendar: true,
    minuteIncrement: 1,
    hourIncrement: 1
  });

  // function initializeSelects (event, element) {
  //   // initOwnershipMemberSelect2(element);
  //   console.log('init app');
  //   $(":not(.modal-select) [rel='select2']").each(function () {
  //     $(this).select2(defaultSelect2Opts);
  //   });
  // }
});

$(document)
  .on('click', '.loading-button', function(e){
    $('.loading-button').removeClass('active')
    $(this).addClass('active')
    e.preventDefault();
    $('#ownership-payouts-table').html('<div class="reports-row"><div class="reports-cell"><span class="loading-title">Kraunama</span></div></div>');
  })
  .on('change', '.upload', function () {
    this.form.submit();
  })
  .on('click', '[data-disable-with]', function (event) {
    var $this = $(this);

    if ($this.hasClass('disabled')) event.preventDefault();
    else $this.addClass('disabled');
  })
  .on('ajax:complete', function (event) {
    $(event.target).parent().find('.disabled[data-disable-with]').removeClass('disabled');
  });

window.lt = lt;

window.fadeReplace = function(element, content){  element.fadeOut(400, function () {
    element.html(content);
    element.fadeIn();
  });
}

window.renderFlash = function(content){
  $('#flashes').append(content);
}

window.toFixed = function(number, precision){
  return +(+(Math.round(+(number + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

window.setSliderPercentageSum = function() {
  var progressBar = $('.progress-bar'),
      percentageSum = toFixed($('.nested-fields:visible .percentage').toArray().reduce(function (accumulator, element) {
        return accumulator + (parseFloat(element.value) || 0);
      }, 0), 2),
      className;

  if (percentageSum === 100) {
    className = 'success';
  } else if (percentageSum > 100) {
    className = 'danger';
  } else if (percentageSum < 100) {
    className = 'warning';
  }


  progressBar.css('width', Math.min(percentageSum, 100) + '%');
  progressBar.text(percentageSum + '%');
  progressBar.removeClass(function (i, classes) {
    return classes.split(' ').filter(function (className) {
      return className.indexOf('bg-') > -1;
    }).join(' ');
  });
  progressBar.addClass('bg-' + className);
}